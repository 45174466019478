/*
*  SHOPPING CART
*/
#reqshipping {
    //margin: 50px auto 0 auto;
    //width: 350px;
    margin: 10px auto 0 auto;
    border: solid 1px #cccccc;
    padding: 20px 20px 5px 20px;
    background: #ffffff;
}
#ajaxbasket #reqshipping {
    margin: 0 auto 0 auto;
}
#reqshipping h3 {
    margin: 0 0 20px 0;
}
#reqshipping label {
    //display: block;
    //float: left;
    //width: 150px;
    //text-align: right;
    //margin: 0 3px 10px 0;
}
#reqshipping input {
    //display: block;
    //float: left;
    //margin: 0 0 10px 0;
}
#reqshipping input.submit {
    //display: inline;
    //float: none;
    //margin: 0;
}
#reqshipping p.footlink {
    clear: both;
    margin: 30px 0 0 0;
    font-size: 88%;
}
.shoppingcart {
    margin: 20px auto 20px auto;
    border: solid 1px #808080;
    padding: 20px;
    background: #ffffff;
    text-align: center;
}
.shoppingcart h3 {
    margin: 0 0 10px 0;
}
.shoppingcart tbody {
    border: none;
    background-color: #fff;
}
.shoppingcart table {
    margin: 0 auto;
    font-size: 100%;
}
.shoppingcart .costs th {
    //background: #bbbbbb;
    background: #fff;
    padding: 3px 5px 3px 5px;
}
.shoppingcart .costs td {
    //background: #e4e4e4;
    background: #f4f4f4;
    padding: 3px 5px 3px 5px;
}
.shoppingcart .num {
    text-align: right;
}
.shoppingcart .tot {
    background: #666644;
    text-align: right;
}
.shoppingcart .costs .blank {
    background: #ffffff;
}
.shoppingcart .nav {
    margin: 20px auto 0 auto;
    color: #808000;
    font-weight: bold;
}
.shoppingcart .code {
    color: #a0a0a0;
    font-size: 80%;
}
fieldset {
	margin: 0 0 1rem 0 !important;
	padding: 0 1rem !important;
	border: solid 1px #a0a0a0 !important;
}
