//  Variations to Settings (bizazz site)
//	----------------------
//
//  Table of Contents:
//
//	1. Color and palette variables
//	2. Top menu
//	3. Accordion
//	4. Tabs
//	5. Cards
//	6. Other bizazz settings
//	7. Overrides of foundation settings
//______________________________________

//	1. Color and palette variables
// -----------------------------------------------------------------------1

$colours: (
	colour1: (
		name: blue,
		color: #0000ff,
		background-color: #fff,
		lighterColour: scale-color(#0000ff, $lightness: +25%),
		veryLightColour: scale-color(#0000ff, $lightness: 80%),
		contrastColour: color-pick-contrast(#0000ff),
	),
	colour2: (
		name: green,
		color: #008000,
		background-color: #fff,
		lighterColour: scale-color(#008000, $lightness: +60%),
		veryLightColour: scale-color(scale-color(#008000, $lightness: +72%), $lightness: 80%),
		contrastColour: color-pick-contrast(#008000),
	),
	//colour3: (
	//	name: palegreen,
	//	color: #006666,
	//	background-color: #fff,
	//	lighterColour: scale-color(#006666, $lightness: +25%),
	//	veryLightColour: scale-color(#006666, $lightness: 80%),
	//	contrastColour: color-pick-contrast(#006666),
	//),
	//colour4: (
	//	name: blue,
	//	color: #006666,
	//	background-color: #fff,
	//	lighterColour: scale-color(#006666, $lightness: +25%),
	//	veryLightColour: scale-color(#006666, $lightness: 80%),
	//	contrastColour: color-pick-contrast(#006666),
	//),
	red: (
		name: red,
		color: #f00,
		background-color: #fff,
		lighterColour: scale-color(#f00, $lightness: +25%),
		veryLightColour: scale-color(#f00, $lightness: 80%),
		contrastColour: color-pick-contrast(#f00),
		textOnly: true,
	),
	white: (
		name: white,
		color: #fff,
		background-color: #000,
		lighterColour: scale-color(#fff, $lightness: +25%),
		veryLightColour: scale-color(#fff, $lightness: 80%),
		contrastColour: color-pick-contrast(#fff),
		textOnly: true,
	)
	,
	primary: (
		name: primary,
		color: $primary-color,
		background-color: #fff,
		lighterColour: scale-color($primary-color, $lightness: +25%),
		veryLightColour: scale-color($primary-color, $lightness: 80%),
		contrastColour: color-pick-contrast($primary-color),
		foundation: true,
	),
	secondary: (
		name: secondary,
		color: $secondary-color,
		background-color: #fff,
		lighterColour: scale-color($secondary-color, $lightness: +25%),
		veryLightColour: scale-color($secondary-color, $lightness: 80%),
		contrastColour: color-pick-contrast($secondary-color),
		foundation: true,
	),
	success: (
		name: success,
		color: $success-color,
		background-color: #fff,
		lighterColour: scale-color($success-color, $lightness: +25%),
		veryLightColour: scale-color($success-color, $lightness: 80%),
		contrastColour: color-pick-contrast($success-color),
		foundation: true,
	),
	alert: (
		name: alert,
		color: $alert-color,
		background-color: #fff,
		lighterColour: scale-color($alert-color, $lightness: +25%),
		veryLightColour: scale-color($alert-color, $lightness: 80%),
		contrastColour: color-pick-contrast($alert-color),
		foundation: true,
	),
	warning: (
		name: warning,
		color: $warning-color,
		background-color: #fff,
		lighterColour: scale-color($warning-color, $lightness: +25%),
		veryLightColour: scale-color($warning-color, $lightness: 80%),
		contrastColour: color-pick-contrast($warning-color),
		foundation: true,
	)
);

// Containers are used only for sites where only part of the content is constrained by grid-container.
// If any containers are defined here then the editor will have the grid-container style added too.
//$containers: (
//	container1: (
//		name: brown,
//		color: #000000,
//		background-color: #c5955e,
//	),
//	container2: (
//		name: grey,
//		color: #000000,
//		background-color: #f6f6f6,
//	),
//	container3: (
//		name: black,
//		color: #ffffff,
//		background-color: #161616,
//	)
//);

@function getColour($scheme, $colour) {
	@return map-get(map-get($colours, $scheme), $colour);
}
// example use for above function; getColour(colour1, name);
// this 'gets' the colour1 name value, which equals brown

//	2. Top menu
// -----------------------------------------------------------------------1

// Top menu colours and padding
$topmenu-background: #e6e6e6;
$topmenu-text: $primary-color;
$topmenu-text-hover: scale-color($topmenu-text, $lightness: +50%);
$topmenu-light: #daeaea;
$topmenu-mid: #009999;
$topmenu-dark: #1e7777;
$topmenu-white: #fff;
//$topmenu-background-current: $topmenu-background;
//$topmenu-colour-current: getColour(colour1, color);

$topmenu-title-padding: 0.7rem 1rem;
$topmenu-topbar-padding: 0.7rem 1rem;
$topmenu-text-decoration: none;

// Titlebar settings
$titlebar-title-color: $topmenu-white;
$titlebar-title-hover: $topmenu-text;
$titlebar-background: $topmenu-background; // override foundation settings
$titlebar-icon-color: $topmenu-text; // override foundation settings
$titlebar-icon-color-hover: $topmenu-text-hover; // override foundation settings
//$titlebar-padding: 0.5rem; // override foundation settings
$titlebar-padding: 0;
// Topbar settings
$topbar-title-color: $topmenu-text;
$topbar-title-hover: $topmenu-text-hover;
$topbar-colour: $topmenu-text;
$topbar-background: $topmenu-background; // override foundation settings
$topbar-colour-hover: $topmenu-text-hover;
$topbar-background-hover: transparent;
$topbar-colour-current: $topmenu-text;
$topbar-background-current: scale-color($topmenu-background, $lightness: +50%);
$topbar-submenu-colour: $topmenu-text;
$topbar-submenu-background: $topmenu-background; // override foundation settings
$topbar-submenu-colour-hover: $topbar-colour-hover;
$topbar-submenu-background-hover: $topmenu-background;
$topbar-submenu-current-colour: $topmenu-text;
$topbar-submenu-current-background: scale-color($topmenu-background, $lightness: +50%);
$topbar-padding: 0; // override foundation settings
$drilldown-colour: $topmenu-text;
$drilldown-background: $topmenu-background;
$drilldown-colour-hover: $topmenu-text-hover;
$drilldown-background-hover: $topmenu-background;
$drilldown-current-colour: $topmenu-text;
$drilldown-current-background: scale-color($topmenu-background, $lightness: +50%);
$dropdownmenu-padding: $topmenu-topbar-padding; // override foundation settings

//	3. Accordion
// -----------------------------------------------------------------------1

$accordionColorDefault: $primary-color;
$accordionBackgroundDefault: $body-background;
$accordionHoverBackgroundDefault: $light-gray;
$accordionRadius: $global-radius;
$accordionRadiusOption: 0.6rem;
$accordionShadowOption: 0 10px 20px rgba(0, 0, 0, 0.2);

//	4. Tabs
// -----------------------------------------------------------------------1

$tabsColorDefault: color-pick-contrast($primary-color);
$tabsBackgroundDefault: $primary-color;
$tabsHoverBackgroundDefault: $light-gray;
//$tabsRadius: $global-radius;
//$tabsRadiusOption: 0.6rem;
//$tabsShadowOption: 0 10px 20px rgba(0, 0, 0, 0.2);

//  5. Cards
// -----------------------------------------------------------------------5

//$cardRadius: $global-radius;
$cardRadius: 0.6rem;
//$cardRadiusOption: 0.6rem;
$cardShadowOption: 0 10px 20px rgba(0, 0, 0, 0.2);

//	6. Other bizazz settings
// -----------------------------------------------------------------------1
$sitehighlight-color: #ff0;

//	7. Overrides of foundation settings
// -----------------------------------------------------------------------3

//	Buttons
//$button-radius: 6px;
//$button-radius: 35px;
//$button-padding: 1rem 2rem;

// Link color
$anchor-color: $primary-color;
$anchor-color-hover: scale-color($anchor-color, $lightness: +32%);

// Lists
//$list-side-margin: 1.25rem; // set ol, ul margin-left
$list-side-margin: 2.25rem;
//$list-nested-side-margin: 1.25rem;
$list-nested-side-margin: 2.25rem;
$list-side-margin-small: 1.25rem; // set ol, ul margin-left on small screen
$list-nested-side-margin-small: 1.25rem;

// Other
//$button-background-hover: scale-color($button-background, $lightness: +32%);
//$button-background-hover-lightness: -25%;
//$global-radius: 0.6rem;
