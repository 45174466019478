// Override (revivalministries)
// --------
//
//  Table of Contents:
//
//	1.
//
//	---- end table of contents ---
//	1.
// -----------------------------------------------------------------------1

$lightgreyBorderColour: #e6e6e6;

.accordion-menu .is-accordion-submenu-parent:not(.has-submenu-toggle) > a::after {
	right: 0;
}

#header {
	border-bottom: 1px solid green;
	background-color: $lightgreyBorderColour;
}
.title-bar {
	.title-bar-title {
		font-size: 1.25rem;
		// height: 45px;
		padding: 5px;
		font-weight: normal;
	}
	// .title-bar-right {
	// 	float: right;
	// }
}

.top-bar{
	.top-bar-title strong {
		color: green;
		&:hover {
			color: #00c000;
		}
	}
}

.card {
	&.biocard {
		border-color: #f00;
		.card-divider {
			background-color: #f00;
			color: #fff;
		}
		.card-section {
			background-color: #fff;
		}
	}
}

#footer {
	border-top: 1px solid green;
	background-color: $lightgreyBorderColour;
}

//.my-fade-in {
//	@include mui-fade(in, $duration: 5s);
//}
//.my-fade-out {
//	@include mui-fade(out, $duration: 3s);
//}

.kenburns .orbit-container {
	overflow: hidden;
}
.kenburns .orbit-slide.is-active img {
	//-webkit-animation: myMove 20s 1 ease-in-out;  
	//-moz-animation: myMove 20s 1 ease-in-out;  
	//-o-animation: myMove 20s 1 ease-in-out;  
	//-ms-animation: myMove 20s 1 ease-in-out;  
	animation: myMove 20s 1 ease-in-out;
}
.kenburns .orbit-slide:not(.is-active) img {
	//visibility: hidden;
}
@keyframes myMove { 
	from { transform: scale(1.0,1.0); transform-origin: 50% 50%; } 
	to { transform: scale(1.3,1.3); transform-origin: 50% 0%; }
}
@-webkit-keyframes myMove { 
	from { -webkit-transform: scale(1.0,1.0); -webkit-transform-origin: 50% 50%; } 
	to { -webkit-transform: scale(1.3,1.3); -webkit-transform-origin: 50% 0%;  }
}
@-o-keyframes myMove { 
	from { -o-transform: scale(1.0,1.0); -o-transform-origin: 50% 50%; } 
	to { -o-transform: scale(1.3,1.3); -o-transform-origin: 50% 0%;  }
}
@-moz-keyframes myMove { 
	from { -moz-transform: scale(1.0,1.0); -moz-transform-origin: 50% 50%; } 
	to { -moz-transform: scale(1.3,1.3); -moz-transform-origin: 50% 0%; }
}
@-ms-keyframes myMove { 
	from { -ms-transform: scale(1.0,1.0); -ms-transform-origin: 50% 50%; } 
	to { -ms-transform: scale(1.3,1.3); -ms-transform-origin: 50% 0%; }
}
